// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-standard-js": () => import("../src/templates/content-standard.js" /* webpackChunkName: "component---src-templates-content-standard-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */)
}

